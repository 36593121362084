/**
 *
 */
export default defineNuxtRouteMiddleware(() => {
    const hotelStore = useHotelStore();
    const localeRoute = useLocaleRoute();

    if (!hotelStore.selectedHotel) {
        return navigateTo(localeRoute({ name: 'upselling-hotel-step-3-hotel-list' }));
    }
});
